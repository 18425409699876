.App {
  font-family: "Montserrat", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contact {
  padding-right: 25%;
  padding-left: 25%;
  /* Apply different padding for different viewport sizes */
  @media (max-width: 1000px) {
    padding: 50px; /* Adjust padding for smaller screens */
  }

  @media (max-width: 480px) {
    padding: 15px; /* Adjust padding for even smaller screens */
  }
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}

.footer {
  align-items: center;
}

a,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

input {
  width: 374px;
}

.statement {
  display: flex;
  justify-content: center;
  width: 50%;
}

.unrelated {
  display: flex;
}

.div-unrelated {
  display: flex;
  padding: 20px;
  max-width: 50%;
  align-items: center;
  justify-content: center;
}

.blue {
  color: #1c6dd0;
}

.orange {
  color: #f5970b;
}

.red {
  color: red;
}

.green {
  color: seagreen;
}

.yellow {
  color: #ffcc1d;
}

.grey {
  color: grey;
}

.pink {
  margin-top: 50px;
  color: orange;
}

.div-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
}

.div-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-skills {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
}

.div-thanks {
  margin: 100px;
  align-items: center;
}

.margin {
  margin: 10px 40px 10px 0px;
}

.div-intro {
  padding: 250px 30px 250px 30px;
}

.div-favourite-projects {
  margin: 10px 40px 10px 40px;
  padding-bottom: 100px;
}

.div-hard-skills {
  margin: 10px 40px 10px 40px;
  padding-bottom: 100px;
}

.h1-no-margin {
  margin: 0;
}

.padded-bottom {
  padding-bottom: 100px;
}
